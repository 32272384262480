@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,semibold,bold,italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:regular,semibold,bold,italic&subset=latin,latin-ext');
/* -----Global------*/

:root {
    --main-black: #303030;
    --main-red: #FF5A60;
    --main-green: #11BF9F;
    --main-gray: #8A8A8A;
    --main-outline: #DADADA;
    --light-outline: #E8E8E8;
    --light-background: #F4F4F4;
    --light-green: #9FE5D8;
    --new-blue: #1C75BC;
    --new-teal: #00AFB7;
}
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: white;
    font-family: Source Sans Pro, sans-serif;
}
button {
    font-family: Source Sans Pro, sans-serif;
    line-height: normal;
}
.mainContent {
    float: left;
    width: 66%;
}
.wrapper {
    width: 76%;
    padding: 0 12%;
}
.flexWrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between
}
a {
    color: var(--new-teal);
    text-decoration: none;
}
hr {
    margin-top: 20px;
}

*, ::after, ::before {
    box-sizing: content-box;
}

/* -----buttons------*/
a.btnPrimary, button.btnPrimary, button[type=submit] {
    color: white;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    background-color: var(--new-blue);
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
}
a.btnPrimary, button.btnPrimary {
    padding: 12px 28px;
}
button[type=submit] {
    margin-top: 16px;
}
a.btnPrimary:hover, button.btnPrimary:hover, button[type=submit]:hover {
    background-color: #3E98E2;
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
}
.btnSecondary {
    border: 1px solid var(--main-outline);
    padding: 8px 16px;
    border-radius: 5px;
}
.btnSecondary:hover {
    border-color: var(--new-teal);
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
}
.btnTertiary {
    font-weight: 600;
}
.btnTertiary:hover {
    color: #58D2BB;
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
}

/* -----header/nav----*/
nav {
    width: 100%;
    margin-bottom: 16px;
    justify-content: space-between;
    padding: 0rem !important;
}
.logo {
    color: var(--new-teal);
    margin-left: 3px; /* correct slight overhang */
    font-family: "Libre Baskerville";
    font-weight: 700;
    font-size: 20px;
    line-height: 80px;
}
nav div.navbar-collapse a {
    color: var(--main-black) !important;
    text-decoration: none;
}
nav div.navbar-collapse a {
    margin-left: 1rem;
    font-size: 14px;
    font-weight: 600;
}
nav .leftNav {
    margin-left: 0;
}
nav div.navbar-collapse a:hover {
    color: var(--new-teal) !important;
    transition: all .2s ease !important;
    -webkit-transition: all .2s ease !important;
}

nav .dropdown-item {
  padding: .5rem 0;
  background-color: transparent !important;
}

/* -----landing page----*/
.articleImg {
    width: 100%;
    padding-top: 20px;
}
header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 56px;
}
h1 {
    font-size: 40px;
    font-weight: 600;
}
header #info {
    width: 100%;
}
header h1 {
    margin: 75px 0 40px 0;
    width: 80%;
}
header .btnTertiary {
    margin-left: 16px;
}
.secondHeader h1 {
    margin-bottom: 40px;
}
.hero {
    margin-top: 50px;
    max-height: 400px;
    min-height: 375px;
    border-radius: 5%;
}
.newDatasets {
    margin-top: 5rem;
}
.newArticles {
    margin-top: 5rem;
}
.mini {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}
.mini a {
    width: 27%;
    border-radius: 10px;
    padding: 24px 24px;
    color: var(--main-black);
    background-color: #eefbf8;
}
#detailsPageLink {
    width: 100%;
}
.mini a:hover {
    /*add box shadow */
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
}
.title {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 14px;
}
.mini .lightTitle {
    margin-top: 14px;
    color: var(--main-gray);
}
.seeMore {
    font-weight: 600;
    float: right;
    margin-top: 8px;
}
.secondHeader {
    margin: 120px 0;
}
.secondHeader img {
    width: 100%;
}
.column {
    float: left;
    width: 50%;
}
.secondHeader .right {
    margin: 100px 0 auto 64px;
}
#randomfactcontainer {
    margin-top: 3em;
    width: 80%;
    background-color: #eefbf8;
    border-radius: 10px;
}
#randomfact {
    color: var(--main-black);
}
#randomdataset {
    margin-left: 1rem;
}
#newfact {
    color: var(--main-gray);
}

/* -----datasets list----*/
#searchInput {
    background-image: url('static/searchIcon.png'); /* Add a search icon to input */
    background-size: 16px;
    background-position: 8px 10px; /* Position the search icon */
    background-repeat: no-repeat; /* Do not repeat the icon image */
    font-family: Source Sans Pro, sans-serif;
    font-size: 16px;
    font-size: 16px; /* Increase font-size */
    padding: 8px 20px 8px 32px; /* Add some padding */
    border: 1px solid var(--main-outline); /* Add a grey border */
    border-radius: 5px;
    margin-bottom: 16px; /* Add some space below the input */
}
#searchInput:focus {
    outline: none;
    border-color: white;
    box-shadow: 0 0 0 2px var(--main-green);
    transition: all .05s ease;
    -webkit-transition: all .05s ease;
}
.datasetFilters {
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
}
#filterDropdowns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
#filterDropdowns .filter {
    padding-right: 10px;
    display: flex;
    width: 100%;
    flex-direction: column;
}
.inline {
    display: inline-block;
    width: 32%;
}
.marginRight {
    margin-right: 2%;
}
.list {
    padding: 0;
    list-style: none;
}
.list li a {
    display: block;
    text-decoration: none;
    border-bottom: 1px solid var(--light-outline);
    padding-bottom: 24px;
    margin-bottom: 24px;
}
.list li a:hover .datasetTitle {
    color: #6E6E6E;
    transition: all .2s ease;
    -webkit-transition: all .2s ease;
}
.list .companyLogo img {
    width: 56px;
    margin-top: 6px;
    margin-left: 12px;
    float: right;
}
.datasetTitle, h3 {
    font-size: 22px;
    font-weight: 600;
    color: var(--main-black);
}
.datasetFacts {
    font-size: 16px;
    color: var(--main-black);
    margin-top: 8px;
}
.datasetFacts span {
    margin-right: 16px;
}
.datasetFacts img {
    height: 12px;
    margin-right: 3px;
}
.datasetInfo {
    color: var(--main-gray);
    margin: 10px 0 3px 0;
    line-height: 22px;
    height: 4.2em;
    overflow: hidden;
    white-space: wrap;
    text-overflow: ellipsis;
}

/* -----sidebar----*/
.sideBar {
    float: right;
    width: 26%;
    padding-left: 8%;
    word-wrap: break-word;
}
.sideBar h1 {
    font-size: 20px;
    line-height: 0;
    margin-bottom: 24px;
    font-weight: 600;
}
.sideBar p {
    line-height: 22px;
    margin-bottom: 24px;
}

.mobile.sideBar {
    line-height: 22px;
    margin-bottom: 24px;
}

.desktop.sideBar {
    line-height: 22px;
    margin-bottom: 24px;
}



/* -----dataset information page----*/
.datasetDetails {
    display: flex;
    flex-direction: row;
}
.datasetDetails .datasetTitle {
    font-size: 32px;
    margin-top: 0px;
    margin-bottom: 24px;
}
.datasetDetails .companyLogo img {
    height: 40px;
    margin-top: 0px;
    margin-left: 8px;
    border-radius: 3px;
    float: right;
}
.datasetDetails a {
    font-weight: 600;
    color: var(--main-green);
}
.datasetDetails .datasetFacts {
    border-bottom: 1px solid var(--light-outline);
    padding-bottom: 24px;
    margin-bottom: 24px;
}
.datasetDetails .datasetFacts p {
    margin-top: 0;
    margin-bottom: 8px;
}
.leftCol, .rightCol {
    float: left;
    width: 50%;
}
.clear {
    clear: left;
}
.greenBackground {
    margin-top: 142px;
    background-color: var(--light-green);
    border-radius: 5px;
    padding: 24px 32px;
}
.greenBackground a {
    color: black;
}
.datasetDetails .sideBar p {
    margin-bottom: 0;
}
.datasetDescription p {
    margin-top: 8px;
}
.detailsColumn1 {
    float: left;
}
.detailsColumn2 {
    float: left;
}

/* -----advice articles-----*/
.articles img {
    float: right;
    width: 200px;
    height: 136px;
    padding-left: 16px;
    margin: auto 0 auto 0;
    object-fit: cover;
}


#datasetCollectionForm {
    /* border: 2px solid var(--main-red); */
    border-radius: 8px;
    display: block;
    margin: auto;
}

/* -----forms (filters, post dataset)-----*/
#root__title { /* formats titles of forms */
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 16px;
}
textarea, select, .postDataset input {
    width: 50%;
    padding: 8px 8px;
    margin-bottom: 16px;
    margin-top: 4px;
    display: block;
    border: 1px solid var(--main-outline);
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 14px;
}
select {
    width: 144px;
}
input[type=checkbox] {
    width: 16px;
    margin-bottom: 0px;
}
input[type=file] {
    margin-top: -12px;   /* not sure why there's extra space below "company logo" */
}
label {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 4px;
}
.checkboxContainer { /* adds space after last checkbox in Post Dataset form */
    margin-bottom: 16px;
}
input[type=checkbox] + label {
    font-weight: 100;
}
fieldset { /* remove border of react json form */
    border: 0;
    padding: 0;
}
legend { /* remove indent of react json form */
    padding: 0;
}


/* -----payment-----*/
.__PrivateStripeElement {  /* controls width of Stripe card element */
    width: 50%;
}
.StripeElement {  /* adds space above and below Stripe card element*/
    margin-top: 4px;
    margin-bottom: 16px;
}

/* -----table-----*/
/*https://www.w3schools.com/css/css_table.asp*/
tr:hover {
    background-color: #f5f5f5;
}

th, td {
    border-bottom: 1px solid #ddd;
  }

tr:nth-child(even) {background-color: #f2f2f2;}

.fixed_header tbody{
    display:block;
    overflow:auto;
    height:200px;
    width:100%;
  }
  .fixed_header thead tr{
    display:block;
  }

  .table_scroll {
    height:500px;
    width:100%;
    overflow:auto;
  }


/* -----footer-----*/
.footer {
    margin-top: 60px;
    margin-bottom: 32px;
    padding: 0 12%;
    color: var(--main-gray);
}



/* -----reponsive layouts-----*/
.mainContent {
    margin: 0 auto;
}
@media only screen and (max-width: 1024px) {
    #contribute {
        width: fit-content;
        padding: 8px 1rem;
    }
    .mainContent {
        width: 100%;
        flex-wrap: wrap;
    }    
    header h1 {
        text-align: center;
        margin-top: 3rem;
        width: 100%;
    }
    .hero {
        display: none;
    }
    #randomfactdiv {
        display: flex;
    }
    #randomfactcontainer {
        width: 100%;
    }
    #lpbuttons {
        display: flex;
        justify-content: center;
    }
    .sideBar {
        display: inline-block;
        width: 100%;
        float: none;
        padding: 0;
        margin: 0;
    }
    .greenBackground {
        margin-top: 16px;
    }
    .mini a {
        width: 100%;
        margin-top: 8px;
    }
    .column {
        float: none;
        width: 100%;
    }
    .secondHeader .right {
        margin-left: 0px;
    }
    textarea, select, .postDataset input, .__PrivateStripeElement {
        width: 70%;
    }

    /* Dataset Page */
    .datasetDetails {
        flex-direction: column;
    }

}
@media only screen and (max-width: 768px){
    .wrapper {
        width: 90%;
        padding: 0 5%;
    }
    .articles img {
        float: right;
        width: 90px;
        height: 58px;
        padding-left: 16px;
        margin: auto 0 auto 0;
        object-fit: cover;
    }
    textarea, select, .postDataset input, .__PrivateStripeElement {
        width: 100%;
    }
    #filterDropdowns {
        flex-direction: column;
    }

}



